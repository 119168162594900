<template>
  <teleport to="body">
    <div v-if="item" class="order-print overflow-a">
      <div class="order-print__head flex ai-c jc-sb pt-10 pb-10 pl-30 pr-30">
        <h4 class="title">Печать заявки №{{ item.id }}</h4>
        <button class="btn grey pl-10 pr-10" @click="closeAction">
          <BaseIcon class="ic-16 red" icon="close" />
        </button>
      </div>

      <div class="order-print__body p-30" id="order-print">
        <BaseLoad v-if="isLoad" class="fixed white z99999" />

        <!-- Order -->
        <div class="order-print__order">
          <div class="flex ai-c jc-sb ggap-10 mb-20">
            <h1 class="title">Заявка №{{ item.id }}</h1>

            <div class="flex ai-c ggap-40">
              <div class="grid ggap-5">
                <small class="t-grey-dark fs-12">Дата создания:</small>
                <h4 class="title">{{ formatDateTime(item.created_at) }}</h4>
              </div>
              <div class="grid ggap-5">
                <small class="t-grey-dark fs-12">Дата обновления:</small>
                <h4 class="title">{{ formatDateTime(item.updated) }}</h4>
              </div>
            </div>
          </div>

          <table class="base-table fs-16 box w-100">
            <thead>
              <th>Описание</th>
              <th>Значение</th>
            </thead>

            <tbody>
              <tr>
                <td class="t-grey-dark">Тип заявки:</td>
                <td>{{ orderType }}</td>
              </tr>

              <tr>
                <td class="t-grey-dark">
                  {{ Number(item.contract_price) ? 'Стоимость договора' : 'Оборот за 1 год' }}:
                </td>
                <td class="t-upper">{{ orderSumm }} {{ item.currency ? item.currency : 'kzt' }}</td>
              </tr>

              <tr>
                <td class="t-grey-dark">Инициатор:</td>
                <td>
                  {{ item.author.fullname }}
                  <small class="fs-12 t-grey-dark">({{ item.author.specialization || 'Без должности' }})</small>
                </td>
              </tr>

              <tr>
                <td class="t-grey-dark">Исполнитель:</td>
                <td>
                  {{ item.responsible.fullname }}
                  <small class="fs-12 t-grey-dark">({{ item.responsible.specialization || 'Без должности' }})</small>
                </td>
              </tr>

              <tr>
                <td class="t-grey-dark">Проект:</td>
                <td>
                  {{ item.project.project }}
                  <small class="fs-12 t-grey-dark">(БИН: {{ item.project.bin }})</small>
                </td>
              </tr>

              <tr>
                <td class="t-grey-dark">Контрагент:</td>
                <td>
                  <template v-if="item.counterparty_bin || item.counterparty_name">
                    {{ item.counterparty_name }}
                    <small v-if="item.counterparty_bin" class="fs-12 t-grey-dark"
                      >(БИН: {{ item.counterparty_bin }})</small
                    >
                  </template>
                  <template v-else>-</template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Agreements -->
        <div v-if="agreements" class="order-print__agreements">
          <div class="flex ai-c jc-sb ggap-10 mb-20 mt-40">
            <h1 class="title">Список согласующих</h1>
            <div class="flex ai-c ggap-20">
              <h4 class="title">Этап №: {{ item.step }}</h4>
              <h4 class="title">Кол-во участников: {{ agreements.length }}</h4>
            </div>
          </div>

          <table class="base-table fs-16 box w-100">
            <thead>
              <th>ФИО</th>
              <th>Должность</th>
              <th>Результат</th>
              <th>Комментарий</th>
              <th>Дата</th>
            </thead>

            <tbody>
              <tr v-for="agree of agreements" :key="agree.id">
                <td>
                  <strong>{{ agree.fullname }}</strong>
                </td>
                <td>
                  {{ agree.specialization || '-' }}
                </td>
                <td>
                  <div class="cut flex ai-c ggap-10">
                    <BaseIcon
                      class="ic-20"
                      :class="agree.status === 2 ? 'green' : 'red'"
                      :icon="currentStatusIcon(agree.status)"
                    />
                    {{ currentStatusText(agree.status) }}
                  </div>
                </td>
                <td>{{ agree.comment || '-' }}</td>
                <td>{{ formatDateTime(agree.created_at) || '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup>
// import { cloneDeep } from 'lodash'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { computed, nextTick, ref } from 'vue'
import { BaseLoad, BaseIcon } from '@/components'
import { formatDateTime } from '@/plugins'

// Data
const route = useRoute()
const router = useRouter()
const store = useStore()
const orderId = route.params.id
const isLoad = ref(true)

// Computed
const item = computed(() => store.getters['orders/item'])
const agreementsStore = computed(() => {
  const arr = store.getters['agreements/rows']
  let res
  if (arr?.length) res = arr.find((el) => el.step === item.value.step)?.items
  return res || []
})
const agreements = computed(() => {
  const res = []

  // Free
  if (item.value?.agreementers?.length) {
    for (let i = 0; i < item.value.agreementers.length; i++) {
      const user = item.value.agreementers[i]
      const currentUser = agreementsStore.value.find((el) => el?.user?.id === user.id)

      res.push({
        id: user.id,
        fullname: user.fullname || '-',
        specialization: user.specialization || '-',
        status: currentUser?.status || -1,
        comment: currentUser?.comment || '-',
        created_at: currentUser?.created_at || ''
      })
    }
  }

  // Departs
  if (item.value?.agreement_departments?.length) {
    for (let i = 0; i < item.value.agreement_departments.length; i++) {
      const depart = item.value.agreement_departments[i]

      // Static users
      if (depart.users.length) {
        for (let ind = 0; ind < depart.users.length; ind++) {
          const user = depart.users[ind]
          const currentUser = agreementsStore.value.find((el) => el?.user?.id === user.id)

          res.push({
            id: user.id,
            fullname: user.fullname || '-',
            specialization: user.specialization || '-',
            status: currentUser?.status || -1,
            comment: currentUser?.comment || '-',
            created_at: currentUser?.created_at || ''
          })
        }
      }

      // Manual users
      if (depart.manual_users.length) {
        for (let ind = 0; ind < depart.manual_users.length; ind++) {
          const user = depart.manual_users[ind]
          const currentUser = agreementsStore.value.find((el) => el?.user?.id === user.id)

          res.push({
            id: user.id,
            fullname: user.fullname || '-',
            specialization: user.specialization || '-',
            status: currentUser?.status || -1,
            comment: currentUser?.comment || '-',
            created_at: currentUser?.created_at || ''
          })
        }
      }
    }
  }

  return res
})
const orderType = computed(() => (item.value?.type === 'create' ? 'Создание документа' : 'Экспертиза'))
const orderSumm = computed(() =>
  Number(item.value?.contract_price)
    ? Number(item.value.contract_price).toLocaleString('ru-RU')
    : Number(item.value?.annual_turnover).toLocaleString('ru-RU')
)

// Created
getData()

// Methods
async function getData() {
  try {
    await store.dispatch('orders/GET_ITEM', orderId)
    await store.dispatch('agreements/GET_ROWS', { orderId, page: 1 })
    isLoad.value = false
    await nextTick()
    window.print()
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
function closeAction() {
  router.back()
}
function currentStatusIcon(status) {
  switch (status) {
    case 0:
      return 'close-circle'
    case 1:
      return 'arrow-repeat'
    case 2:
      return 'check-circle'
    default:
      return 'alert-triangle'
  }
}
function currentStatusText(status) {
  switch (status) {
    case 0:
      return 'Не согласовано'
    case 1:
      return 'Не согласовано - вернуть на рассмотрение'
    case 2:
      return 'Согласовано'
    default:
      return 'Не участвовал (а)'
  }
}
</script>

<style lang="scss" scoped>
.order-print {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: var(--box);

  &__head {
    background-color: var(--box);
    border-bottom: var(--b1);
    position: sticky;
    top: 0;
    z-index: 1;

    @media print {
      display: none;
    }
  }

  &__body {
    .base-table {
      td {
        border-right: var(--b1);
        &:last-child() {
          border: 0;
        }
      }
    }
    @media print {
      // font-family: Avenir, Arial, sans-serif;

      .t-grey-dark {
        color: #ddd;
      }
    }
  }
}
</style>
